import React, { useState, useEffect } from "react";
import './../Signup.css';
import './../ProfileInfo.css';
import './../../../../styles/defaultDesign.css';
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";
import Introduction from "../../../../components/SignUpComponents/Introduction";

/* eslint-disable no-unused-vars */
function AlumniThirdPage({ formData, handleChange, goToNextPage, goToPreviousPage }) {
  const fieldOptions = [
    '영업', '경영/사무', '마케팅',
    '생산/제조', '연구/설계', 'IT/개발',
    '서비스', '무역/유통', '의료/제약',
    '예술/디자인', '교육', '건설',
    '미디어', '전문/특수직', '법률',
    '금융', '농림/어업', '기타'
  ]

  const [fields, setFields] = useState({
    field1: formData.field1,
    field2: formData.field2,
    field3: formData.field3
  });

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [isValidLocation, setIsValidLocation] = useState(false);
  const [isThirdDone, setIsThirdDone] = useState(false);

  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isLoadingCity, setIsLoadingCity] = useState(false);

  useEffect(() => {
    setIsThirdDone(
      isValidLocation && 
      (
        formData["field1"].length > 0 || 
        formData["field2"].length > 0 || 
        formData["field3"].length > 0
      ) &&
      formData["introduction"].length > 0 
    )
  }, [formData, isValidLocation]);

  // Load countries on component mount
  useEffect(() => {
    async function fetchCountries() {
      const result = await GetCountries();
      setCountriesList(result);
    }
    fetchCountries();
  }, []);

  // Validate location based on current state
  useEffect(() => {
    if (!isLoadingState && !isLoadingCity) {
      if (country && state && city) {
        setIsValidLocation(true);
      } else if (country && state && cityList.length === 0) {
        setIsValidLocation(true);
      } else if (country && stateList.length === 0) {
        setIsValidLocation(true);
      } else {
        setIsValidLocation(false);
      }
    } else {
      setIsValidLocation(false);
    }
  }, [country, state, city, countriesList, stateList, cityList, isLoadingState, isLoadingCity]);

  // whenever components are mounted, store the necessary location information in local storage
  useEffect(() => {
    const savedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    const savedState = JSON.parse(localStorage.getItem('selectedState'));
    const savedCity = JSON.parse(localStorage.getItem('selectedCity'));

    const loadCountries = async () => {
      const countries = await GetCountries(); // fetch country list
      setCountriesList(countries);

      if (savedCountry) {
        setCountry(savedCountry.name);
        setCountryid(savedCountry.id);

        // fetch state list
        const states = await GetState(savedCountry.id);
        setStateList(states);
      }

      if (savedState) {
        setState(savedState.name);
        setStateid(savedState.id);

        // fetch city list
        const cities = await GetCity(savedCountry?.id, savedState.id);
        setCityList(cities);
      }

      if (savedCity) {
        setCity(savedCity.name);
        setCityid(savedCity.id);
      }
    };

    loadCountries();
  }, []); // call once when components are mounted

  // update country 
  const onClickCountry = async (e) => {
    const selectedCountry = countriesList.find(
      (country) => country.name === e.target.value
    );
    if (selectedCountry) {
      setCountry(selectedCountry.name);
      setCountryid(selectedCountry.id);
      setState('');
      setStateid(0);
      setCity('');
      setCityid(0);
      setCityList([]);
      setIsLoadingState(true); // Start loading state

      // store states
      localStorage.setItem('selectedCountry', JSON.stringify(selectedCountry));

      const result = await GetState(selectedCountry.id);
      setStateList(result);
      setIsLoadingState(false); // End loading state
      handleChange({ target: { name: 'country', value: selectedCountry.name } });
    }
  };

  // update state
  const onClickState = async (e) => {
    const selectedState = stateList.find(
      (state) => state.name === e.target.value
    );
    if (selectedState) {
      setState(selectedState.name);
      setStateid(selectedState.id);
      setCity('');
      setCityid(0);
      setIsLoadingCity(true); // Start loading city

      // store states
      localStorage.setItem('selectedState', JSON.stringify(selectedState));

      const result = await GetCity(countryid, selectedState.id);
      setCityList(result);
      setIsLoadingCity(false); // End loading city
      handleChange({ target: { name: 'state', value: selectedState.name } });
    }
  };

  // update city
  const onClickCity = (e) => {
    const selectedCity = cityList.find(
      (city) => city.name === e.target.value
    );

    if (selectedCity) {
      setCity(selectedCity.name);
      setCityid(selectedCity.id);

      // store states
      localStorage.setItem('selectedCity', JSON.stringify(selectedCity));

      handleChange({ target: { name: 'city', value: selectedCity.name } });
    }
  };

  const onClickFields = (option) => {
    setFields((prevFields) => {
      const fieldArray = [prevFields.field1, prevFields.field2, prevFields.field3];
  
      if (fieldArray.includes(option)) {
        // delete a duplicate(s)
        fieldArray[fieldArray.indexOf(option)] = '';
      } else {
        // Add the option if it's not already selected and if there is space
        const emptyIndex = fieldArray.indexOf('');
        if (emptyIndex !== -1) {
          fieldArray[emptyIndex] = option;
        }
      }
  
      const newFields = {
        field1: fieldArray[0] || '',
        field2: fieldArray[1] || '',
        field3: fieldArray[2] || ''
      };
  
      return newFields;
    });
  };

  useEffect(() => {
    handleChange({ target: { name: 'field1', value: fields.field1 } });
    handleChange({ target: { name: 'field2', value: fields.field2 } });
    handleChange({ target: { name: 'field3', value: fields.field3 } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  return (
    <div className="Profile--content-container huge-gap">
      {/* Location */}
      <div className="Profile--content-section wide-gap">
        <div>
          <span className="b7-16-sb" style={{ color: "#66707A"}}>현재 활동 위치 </span>
          <span style={{ color: "#FE3C2A"}}>*</span>
        </div>

        <div className="Profile--content-section wide-gap">
          {/* Country selection */}
          <select
            id="country"
            className={"Profile--dropdown-menu" + (formData.country === "" ? " placeholder" : "")}
            onChange={(e) => onClickCountry(e)}
            value={formData.country}
          >
            <option value="" disabled>
              국가 선택
            </option>
            {countriesList.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>

          {/* State selection */}
          <select
            id="state"
            className={"Profile--dropdown-menu" + (formData.state === "" ? " placeholder" : "")}
            onChange={(e) => onClickState(e)}
            value={formData.state}
            disabled={!formData.country || stateList.length === 0}
          >
            <option value="" disabled>
              주 선택
            </option>
            {stateList.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>

          {/* City selection */}
          <select
            id="city"
            className={"Profile--dropdown-menu" + (formData.city === "" ? " placeholder" : "")}
            onChange={(e) => onClickCity(e)}
            value={formData.city}
            disabled={!formData.state || cityList.length === 0}
          >
            <option value="" disabled>
              도시 선택
            </option>
            {cityList.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Fields */}
      <div className="Profile--content-section wide-gap">
        <div>
          <span className="b7-16-sb" style={{ color: "#66707A"}}>분야(최대 3개) </span>
          <span style={{ color: "#FE3C2A"}}>*</span>
        </div>

        <div className="Profile--button-container fields">
          {
            fieldOptions.map((option, index) => (
              <button 
                className={"Profile--button field" + ([formData.field1, formData.field2, formData.field3].includes(option) ? " selected" : "")}
                key={option}
                onClick={() => onClickFields(option)}
                defaultValue={formData["field1"]}
              >
                <span className="b7-16-sb">
                  {option}
                </span>
              </button>
            ))
          }
        </div>
      </div>
      
      {/* Introduction */}
      <Introduction 
        formData={formData}
        handleChange={handleChange}
        title={'한줄 소개'}
        placeholder={'ex. 게임을 좋아하는 지비집 프로덕트 디자이너'}
        name={"introduction"}
      />

      <div className="Profile--button-container navigate">
        <button 
          className="Profile--navigate-button prev"
          onClick={goToPreviousPage}
        >
          <span className="h2-18-sb">이전</span>
        </button>
        <button 
          className="Profile--navigate-button"
          onClick={goToNextPage}
          disabled={!isThirdDone}
        >
          <span className="h2-18-sb">다음</span>
        </button>
      </div>
    </div>
  )
}

export default AlumniThirdPage;